export default [
  'Cannabis referral',
  'Ear Infection (Acute Otitis Media)',
  'URTI (Upper respiratory tract infection)',
  'BP Check and HTN medication refill',
  'OCP renewal (Birth Control)',
  'Acute Conjunctivitis',
  'Strep Throat',
  'Pregnancy Test',
  'Rash',
  'Headache (Migraine vs Cluster vs Tension)',
  'Urinary Tract Infection',
  'Urinary Tract Infection followup',
  'SSRI refill',
  'Dental Abscess/ Cellulitis',
  'Gastroenteritis',
  'Eczema',
  'Anxiety',
  'Ingrown Toenail',
  'Onychomycosis (Nail Fungus)',
  'Musculoskeletal Injuries',
  'Lower Back Pain',
  'Knee Pain',
  'Viagra Renewal',
  'Anaphylaxis (Allergic reaction)',
  'Croup',
  'Abdominal Pain',
  'High Blood Pressure',
  'Specialist Referral',
  'Asthma / Asthma Flare up',
  'Dysfunctional Periods',
  'Cold Sore / Canker in Mouth',
  'Prescription Renewal',
  'Covid-19 Vaccine Assessment',
  'Fertility referral',
];
