<template>
  <register-container>
    <template slot="header">{{ pageTitle || $t('registration.registerInform.title') }} </template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndDown && 'justify-center '"
      class="register-inform__row"
    >
      <v-col cols="12" md="6" class="pa-0 pr-sm-2 d-flex flex-column">
        <!-- Communication Preference Buttons-->
        <div
          class="d-flex mb-5 flex-column"
          style="max-width: 100%"
          :class="$vuetify.breakpoint.sm ? 'justify-center' : 'justify-space-between'"
        >
          <p class="h6-text" :class="commPreferenceSubtitle ? 'mb-0' : 'mb-2'">
            {{ $t('registration.registerInform.visitType') }}
          </p>
          <p class="mb-2" style="font-size: 0.7em" v-if="commPreferenceSubtitle">
            {{ commPreferenceSubtitle }}
          </p>
          <div class="d-flex justify-space-between">
            <hover-button
              card="true"
              :disabled="disabledAppointmentTypes.includes(APPOINTMENT_TYPES.primaryPhone)"
              @click="appointmentType = APPOINTMENT_TYPES.primaryPhone"
              :selected="appointmentType === APPOINTMENT_TYPES.primaryPhone"
            >
              <v-card-title class="d-flex flex-column weather-icon">
                <v-icon>mdi-phone</v-icon>
                {{ $t('main.phone') }}
              </v-card-title>
            </hover-button>

            <hover-button
              card="true"
              :disabled="disabledAppointmentTypes.includes(APPOINTMENT_TYPES.video)"
              @click="appointmentType = APPOINTMENT_TYPES.video"
              :selected="appointmentType === APPOINTMENT_TYPES.video"
            >
              <v-card-title class="d-flex flex-column weather-icon">
                <v-icon>mdi-video</v-icon>
                {{ $t('main.video') }}
              </v-card-title>
            </hover-button>
            <hover-button
              card="true"
              :disabled="disabledAppointmentTypes.includes(APPOINTMENT_TYPES.inPerson)"
              @click="appointmentType = APPOINTMENT_TYPES.inPerson"
              :selected="appointmentType === APPOINTMENT_TYPES.inPerson"
            >
              <v-card-title class="d-flex flex-column weather-icon">
                <v-icon>mdi-account</v-icon>
                {{ $t('main.inPerson') }}
              </v-card-title>
            </hover-button>
          </div>
        </div>
        <!-- Reason(s) for visit field-->
        <div class="d-flex flex-column">
          <p class="h6-text mb-2">{{ $t('registration.registerInform.reason') }}</p>

          <div class="d-flex justify-space-between mr-1" :class="reasonForVisitClass">
            <div class="flex-grow-1 reason-select mb-md-0 mb-1">
              <v-combobox
                :items="filteredAppointmentReasons"
                @change="onReasonSelect($event)"
                hide-details
                outlined
                v-model="reasonForVisit"
                :disabled="isReasonForVisitFixed"
                clearable
                @input.native="onReasonsInput"
                :menu-props="{ closeOnClick: true }"
              >
                <template v-slot:no-data>
                  <v-list-item-group>
                    <v-list-item>
                      <v-list-item-content>
                        {{
                          $t('registration.registerInform.customReason', {
                            reason: reasonForVisit,
                          })
                        }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </template>
              </v-combobox>
            </div>
            <v-slide-x-transition>
              <div
                v-if="reasonForVisit === PRESCRIPTION_RENEWAL"
                class="other-input mt-0 ml-0 ml-md-2"
                key="prescription-options"
              >
                <v-autocomplete
                  outlined
                  :class="tenantUrl + '-theme-text-field'"
                  :placeholder="$t('registration.registerInform.prescriptionSearch')"
                  :items="drugs"
                  v-model="prescriptionSelection"
                  :search-input.sync="search"
                  :error-messages="prescriptionSelectionErrors"
                  @change="handleAddPrescription"
                  @blur="handleBlurPrescription"
                  clearable
                  hide-no-data
                  return-object
                  cache-items
                  :loading="searchLoading"
                  item-text="brandName"
                ></v-autocomplete>
              </div>
              <div
                v-if="reasonForVisit === CANNABIS_REFERRAL"
                class="mt-0 ml-0 ml-md-2"
                :style="{ width: this.$vuetify.breakpoint.mdAndUp ? '200px' : '100%' }"
                key="cannabis-options"
              >
                <v-autocomplete
                  outlined
                  :class="tenantUrl + '-theme-text-field'"
                  :placeholder="$t('registration.registerInform.reasonForReferral')"
                  :items="cannabisOptions"
                  v-model="additionalComments"
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
            </v-slide-x-transition>
          </div>
          <div v-if="reasonForVisit === PRESCRIPTION_RENEWAL" class="prescriptions-list mb-10">
            <div
              v-for="(prescription, index) in prescriptionSelections"
              :ref="'prescriptionSelection' + index"
              :key="index"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="error"
                        @click="removePrescription(index)"
                        ><v-icon>mdi-close-circle-outline</v-icon></v-btn
                      >
                    </template>
                    <span> {{ $t('registration.registerInform.removePrescription') }} </span>
                  </v-tooltip> </v-list-item-icon
                ><v-list-item-content>
                  <v-list-item-title
                    >{{ drugName(prescription.brandName) }}
                  </v-list-item-title></v-list-item-content
                ></v-list-item
              >
            </div>
            <div
              class="prescription-spacer"
              v-if="$vuetify.breakpoint.smAndUp && this.prescriptionSelections.length > 1"
            />
          </div>
        </div>

        <!-- Additional Comments -->
        <div class="d-flex flex-column mb-2" v-if="showAdditionalComments">
          <p class="h6-text mb-5">{{ $t('registration.registerInform.additionalComments') }}</p>

          <div
            class="d-flex mb-0 justify-space-between mr-1"
            :class="this.$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-row'"
          >
            <div class="flex-grow-1 reason-select">
              <v-text-field
                v-model="additionalComments"
                outlined
                :placeholder="$t('registration.registerInform.optional')"
                :error-messages="additionalCommentsErrors"
                @change="$v.additionalComments.$touch()"
                @blur="$v.additionalComments.$touch()"
              ></v-text-field>
            </div>
          </div>
        </div>
      </v-col>

      <!-- Form questions -->
      <v-col cols="12" md="6" class="d-flex flex-column justify-space-between pa-0 pl-sm-2">
        <div v-for="(question, index) in form" :key="question.variableName">
          <v-div
            v-if="question.show && (!question.showIf || question.showIf(form))"
            @hook:mounted="onQuestionMounted(index)"
            :class="{
              'mb-0 justify-space-between':
                (form.questionFour.show && index === 'questionFour') ||
                (!form.questionFour.show && index === 'questionThree'),
            }"
            :id="`form-question-${index}`"
          >
            <p
              class="h6-text mb-2"
              :class="{
                'd-flex':
                  (form.questionFour.show && index === 'questionFour') ||
                  (!form.questionFour.show && index === 'questionThree'),
              }"
            >
              {{
                !question.show
                  ? null
                  : question.displayText ||
                    `${$t(`registration.registerInform.questions.${question.variableName}`)}`
              }}
              <v-tooltip right v-if="question.infoText">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="accent" class="ml-1"
                    >mdi-information</v-icon
                  >
                </template>
                <span>{{ question.infoText }}</span>
              </v-tooltip>
            </p>

            <div v-if="question.type === 'input'">
              <v-text-field
                v-model="question.value"
                outlined
                :type="question.inputType"
                :data-cy="`${question.variableName}--input`"
                :placeholder="question.optional ? $t('registration.tags.optional') : ''"
                :min="question.min"
                :max="question.max"
                v-mask="question.mask || null"
                @input="
                  (e) => (e < question.min ? (question.value = question.min) : (question.value = e))
                "
              />
            </div>

            <div v-else-if="question.type === 'select'" class="d-flex">
              <v-autocomplete
                :items="question.items"
                outlined
                v-model="question.value"
                @change="changeSelect($event, question)"
                :data-cy="`${question.variableName}--input`"
              />
              <v-text-field
                v-model="question.selection"
                v-if="question.value === 'Other'"
                outlined
                class="pl-1"
              />
            </div>
            <div
              v-else
              class="d-flex mb-5 justify-space-between"
              :style="
                (form.questionFour.show && index === 'questionFour') ||
                (!form.questionFour.show && index === 'questionThree')
                  ? { 'margin-bottom': '36px!important' }
                  : ''
              "
            >
              <hover-button @click="question.value = true" :selected="question.value">
                {{ $t('main.yes') }}
              </hover-button>

              <hover-button @click="question.value = false" :selected="question.value === false">
                {{ $t('main.no') }}
              </hover-button>
            </div>
          </v-div>
        </div>
      </v-col>
    </v-row>
  </register-container>
</template>
<style lang="scss">
.register-inform__row .prescription-spacer {
  height: 115px;
}
.v-menu__content {
  z-index: 10000 !important;
}
.other-input {
  width: 100%;
}
@media all and (min-width: 600px) {
  .prescriptions-list {
    max-height: 100px !important;
  }
  .other-input {
    width: 400px;
  }
}
@media all and (max-width: 960px) {
  .register-inform__row {
    .col-12 {
      max-width: 600px;
    }
  }
}
</style>

<script>
import { maxLength, requiredIf, required } from 'vuelidate/lib/validators';

import to from 'await-to-js';
import { VUELIDATE_ERRORS } from '../constants/validation';
import RegisterContainer from '../components/RegisterContainer.vue';
import HoverButton from '../components/HoverButton.vue';
import appointmentReasons from '../constants/appointmentReasons';
import GET_DRUGS from '../graphql/Query/GetDrugs.gql';
import { APPOINTMENT_TYPES } from '../constants/appointment';
import VDiv from '../components/Utility/VDiv.vue';

const questionIsValid = (value) => {
  if (!value.show || value.optional) return true;
  if (value.type) {
    return !!value.value;
  }
  return typeof value.value === 'boolean';
};

export default {
  name: 'RegisterInform',
  mixins: [VUELIDATE_ERRORS],
  components: { RegisterContainer, HoverButton, VDiv },
  data() {
    return {
      cannabisOptions: [
        this.$t('registration.cannabis.registerInform.psych'),
        this.$t('registration.cannabis.registerInform.nonPsych'),
      ],
      // < --- tenant customizable options --->

      form: {
        questionOne: {
          variableName: 'annualCheckup',
          // default is $t('registration.registerInform.questions [variableName]')
          // if displayText is null
          displayText: null,
          // label of note saved to member notes
          noteLabel: 'Has had annual checkup?',
          // text of (optional) info bubble
          // info bubble hidden if infoText is null
          infoText: null,
          show: true,
          value: null,
        },
        questionTwo: {
          variableName: 'seeSpecialist',

          displayText: null,
          noteLabel: 'Specialist Requested',
          show: true,
          value: null,
        },
        questionThree: {
          variableName: 'docNoteRequired',
          displayText: null,
          noteLabel: 'Doctor Note',
          infoText: this.$t('registration.registerInform.noteFee'),
          show: true,
          value: null,
        },
        questionFour: {
          variableName: 'questionFour',
          displayText: null,
          noteLabel: 'questionFourLabel',
          infoText: 'questionFourText',
          show: false,
          value: null,
        },
      },
      // reasonForVisit will be disabled
      // default value of reasonForVisit will be submitted
      enabledAppointmentTypes: [],
      PRESCRIPTION_RENEWAL: 'Prescription Renewal',
      CANNABIS_REFERRAL: this.$t('registration.cannabis.registerInform.cannabisReferral'),
      isReasonForVisitFixed: false,
      reasonForVisit: null,
      showAdditionalComments: false,
      // <--- end tenant customizable options --->
      additionalComments: null,
      appointmentReasons,
      APPOINTMENT_TYPES,
      appointmentType: null,
      commPreferenceSubtitle: null,
      drugs: [],
      hover: null,
      prescriptionSelection: null,
      prescriptionSelections: [],
      search: null,
      searchLoading: false,
      mounted: false,
    };
  },
  validations() {
    return {
      additionalComments: {
        required: requiredIf(() => {
          return this.reasonForVisit === this.CANNABIS_REFERRAL;
        }),
        maxLength: maxLength(100),
      },
      appointmentType: {
        required,
      },
      form: {
        questionOne: {
          questionIsValid,
        },
        questionTwo: {
          questionIsValid,
        },
        questionThree: {
          questionIsValid,
        },
        questionFour: {
          questionIsValid,
        },
      },
      reasonForVisit: {
        required,
        maxLength: maxLength(90),
      },
      prescriptionSelection: {
        required: requiredIf(() => {
          return (
            this.reasonForVisit === this.PRESCRIPTION_RENEWAL && !this.prescriptionSelections.length
          );
        }),
      },
    };
  },

  computed: {
    completed() {
      return !this.$v.$invalid;
    },
    disabledAppointmentTypes() {
      // FOR QA: Enable appointment type "video" for pen-test tenants
      const penTestUrls = ['pen-test-1', 'pen-test-2', 'otn-demo'];
      let array = [];
      if (penTestUrls.includes(this.tenantUrl)) {
        array = [APPOINTMENT_TYPES.video, APPOINTMENT_TYPES.inPerson];
      } else {
        array = [
          APPOINTMENT_TYPES.video,
          APPOINTMENT_TYPES.inPerson,
          APPOINTMENT_TYPES.primaryPhone,
        ];
      }
      return array.filter((type) => !this.enabledAppointmentTypes.includes(type));
    },
    remainingFields() {
      return [
        {
          name: 'appointmentType',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerInform.visitType'),
          }),
          valid: !this.$v.appointmentType.$invalid,
        },
        {
          name: 'questionOne',
          displayText: this.$t('registration.errors.missingAnswer', {
            question:
              this.form.questionOne.displayText ||
              this.$t(
                `registration.registerInform.questions.${this.form.questionOne.variableName}`
              ),
          }),
          valid: !this.$v.form.questionOne.$invalid,
        },
        {
          name: 'questionTwo',
          displayText: this.$t('registration.errors.missingAnswer', {
            question:
              this.form.questionTwo.displayText ||
              this.$t(
                `registration.registerInform.questions.${this.form.questionTwo.variableName}`
              ),
          }),
          valid: !this.$v.form.questionTwo.$invalid,
        },
        {
          name: 'questionThree',
          displayText: this.$t('registration.errors.missingAnswer', {
            question:
              this.form.questionThree.displayText ||
              this.$t(
                `registration.registerInform.questions.${this.form.questionThree.variableName}`
              ),
          }),
          valid: !this.$v.form.questionThree.$invalid,
        },
        {
          name: 'questionFour',
          displayText: this.$t('registration.errors.missingAnswer', {
            question: !this.form.questionFour?.show
              ? null
              : this.form.questionFour.displayText ||
                this.$t(
                  `registration.registerInform.questions.${this.form.questionFour.variableName}`
                ),
          }),
          valid: !this.$v.form.questionFour.$invalid,
        },
        {
          name: 'reasonForVisit',
          displayText: this.$t('registration.errors.missingField', {
            field: this.$t('registration.registerInform.reason'),
          }),
          valid: !this.$v.reasonForVisit.$invalid,
        },
        {
          name: 'additionalComments',
          displayText: this.$t('registration.errors.missingField', {
            field: 'Reason for referral',
          }),
          valid: !this.$v.additionalComments.$invalid,
        },
      ];
    },
    filteredAppointmentReasons() {
      const filteredReasons = [
        { header: this.$t('registration.registerInform.customReasonPrompt') },
        ...this.appointmentReasons,
      ].filter((reason) => {
        if (!this.reasonForVisit) return true;
        if (typeof reason === 'object') {
          if (!this.reasonForVisit.length) {
            return true;
          }
          return false;
        }
        const searchQuery = new RegExp(this.reasonForVisit.toLowerCase());
        return searchQuery.test(reason.toLowerCase());
      });

      return filteredReasons;
    },
    questionvalue() {
      return !!this.form.questionOne.value;
    },
    appointmentDetailsNotes() {
      const appDetails = [
        `Contact By: ${this.appointmentType}`,

        `Reason(s): ${this.appointmentReason}`,

        `${this.form.questionOne.noteLabel}: ${
          this.form.questionOne.selection || this.form.questionOne.value
        }`,
        `${this.form.questionTwo.noteLabel}: ${
          this.form.questionTwo.selection || this.form.questionTwo.value
        }`,
        `${this.form.questionThree.noteLabel}: ${
          this.form.questionThree.selection || this.form.questionThree.value
        }`,
      ];
      if (this.form.questionFour) {
        appDetails.push(
          `${this.form.questionFour.noteLabel}: ${
            this.form.questionFour.selection || this.form.questionFour.value
          }`
        );
      }
      if (this.form.questionFive) {
        appDetails.push(
          `${this.form.questionFive.noteLabel}: ${
            this.form.questionFive.selection || this.form.questionFive.value
          }`
        );
      }
      if (this.additionalComments) {
        appDetails.splice(3, 0, `Additional Comments: ${this.additionalComments}`);
      }
      return appDetails;
    },
    appointmentReason() {
      if (this.reasonForVisit === this.PRESCRIPTION_RENEWAL) {
        const prescriptions = this.prescriptionSelections
          .map((p) => {
            return p.brandName;
          })
          .join(', ');
        return `${this.reasonForVisit} -- ${prescriptions}`;
      }
      return this.reasonForVisit;
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
    prescriptions() {
      return this.prescriptionSelections
        .map((p) => {
          return p.brandName;
        })
        .join(', ');
    },
    reasonForVisitClass() {
      const classes = [];
      if (this.$vuetify.breakpoint.mdAndUp) {
        classes.push('flex-row');
      } else {
        classes.push('flex-column');
      }
      if (
        this.reasonForVisit === this.PRESCRIPTION_RENEWAL ||
        this.reasonForVisit === this.CANNABIS_REFERRAL
      ) {
        classes.push('mb-0');
      } else {
        classes.push('mb-6 mb-sm-9');
      }
      return classes;
    },
  },
  methods: {
    onQuestionMounted(index) {
      if (!this.mounted) return;
      const questionEl = document.getElementById(`form-question-${index}`);
      if (!questionEl) return;
      questionEl.scrollIntoView({ behavior: 'smooth' });
    },
    changeSelect(e, q) {
      // eslint-disable-next-line no-param-reassign
      if (e !== 'Other') q.selection = null;
    },
    drugName(name) {
      if (name.length > 20) {
        return `${name.substr(0, 20)}...`;
      }
      return name;
    },
    getConfig() {
      const config = this.$store.getters.getConfig(this.$options.name);
      if (!config) return;
      Object.keys(config).forEach((item) => {
        if (typeof config[item] === 'object') {
          Object.assign(this[item], config[item]);
        } else {
          this[item] = config[item];
        }
      });
    },
    async getDrugs() {
      this.searchLoading = true;
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_DRUGS,
          variables: {
            options: {
              brandName: this.search,
            },
          },
        })
      );
      if (errors) {
        console.log(errors);
      } else {
        this.drugs = query.data.getDrugs;
      }
      this.searchLoading = false;
    },

    getInitialState() {
      /* 1) fetch the custom config (if any) for this component and assign values */
      this.getConfig();

      /* 2) fetch users selections from store, if any are saved */
      const reason = this.$store.state.registration.appointmentReasons || this.reasonForVisit;
      if (!this.isReasonForVisitFixed) {
        if (reason) {
          this.reasonForVisit = reason;
        } else if (reason === null) {
          this.reasonForVisit = null;
        }
      }

      if (this.reasonForVisit === this.PRESCRIPTION_RENEWAL) {
        this.prescriptionSelections = [...this.$store.state.registration.prescriptionSelections];
      }

      /* 2b) fetch additionalComments */
      const { additionalComments } = this.$store.state.registration;

      this.additionalComments = additionalComments;

      /* 2c) fetch users selections to form questions */
      let preferences = {};
      const selections = this.$store.state.registration.patientData.notes.basicNotes;
      if (selections) {
        selections.forEach((selection) => {
          preferences = {
            ...preferences,
            [selection.split(': ')[0]]: selection.split(': ')[1],
          };
        });
        const getBool = {
          false: false,
          true: true,
          null: null,
        };

        this.appointmentType = preferences['Contact By'];

        Object.keys(this.form).forEach((key) => {
          const question = this.form[key];
          if (!question) return;
          if (!question.type) {
            question.value = getBool[preferences[question.noteLabel]];
          } else if (
            question.type === 'select' &&
            !!preferences[question.noteLabel] &&
            !question.items.includes(preferences[question.noteLabel])
          ) {
            question.value = 'Other';
            question.selection = preferences[question.noteLabel];
          } else {
            question.value = preferences[question.noteLabel];
          }
        });
        /* 2d) fetch appointment type */
        const { appointmentType } = this.$store.state.registration;
        if (appointmentType) this.appointmentType = appointmentType;
      }
    },
    handleAddPrescription(e) {
      if (e) this.$v.prescriptionSelection.$touch();

      if (this.$v.$invalid) return;
      if (this.prescriptionSelections.some((p) => p.brandName === e.brandName)) {
        this.$nextTick(() => {
          this.prescriptionSelection = null;
        });
        return;
      }
      this.prescriptionSelections.push(e);
      this.$nextTick(() => {
        this.prescriptionSelection = null;
        const refName = `prescriptionSelection${this.prescriptionSelections.length - 1}`;
        this.$refs[refName][0].scrollIntoView({ block: 'center' }, 'smooth');
      });
    },
    handleBlurPrescription() {
      if (this.prescriptionSelection) this.$v.prescriptionSelection.$touch();
    },
    onNextClick() {
      if (this.reasonForVisit === this.PRESCRIPTION_RENEWAL) {
        this.additionalComments = this.prescriptions;
      }
      this.$store.commit('addDefaultPatientNotes', {
        basicNotes: [...this.appointmentDetailsNotes],
      });
      this.$store.commit('setRegistration', {
        appointmentReasons: this.reasonForVisit,
        additionalComments: this.additionalComments,
        appointmentType: this.appointmentType,
        reasonForVisit: this.reasonForVisit,
      });
      this.$store.commit('addOtherPatientNote', {
        newNoteText: `${this.reasonForVisit}${
          this.additionalComments ? `\n${this.additionalComments}` : ''
        }`,
        noteTags: ['Primary Complaint'],
      });
      if (this.reasonForVisit === this.PRESCRIPTION_RENEWAL) {
        this.$store.commit('setRegistration', {
          prescriptionSelections: this.prescriptionSelections,
        });
      }
      this.$store.commit('setCompleted', false);
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
    onReasonsInput(e) {
      this.reasonForVisit = e.srcElement.value;
    },
    onReasonSelect(reason) {
      this.reasonForVisit = reason;
    },
    removePrescription(index) {
      this.prescriptionSelections.splice(index, 1);
    },
  },
  mounted() {
    this.$store.commit('setCompleted', this.completed);
    this.$store.commit('setOnNextClick', this.onNextClick);
    this.$nextTick(() => {
      this.getInitialState();
      this.mounted = true;
    });
  },
  watch: {
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
    async search(value) {
      if (this.searchLoading) return;
      if (value?.length > 1) await this.getDrugs();
      if (!value?.length) this.drugs = [];
    },
  },
};
</script>
